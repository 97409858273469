import React, { useState } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Modal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    amount: "",
    duration: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Valid email is required.";
    if (!formData.phone.trim() || !/^\d{11}$/.test(formData.phone))
      newErrors.phone = "Valid phone number is required.";
    if (!formData.amount || formData.amount <= 0)
      newErrors.amount = "Amount must be greater than zero.";
    // if (!formData.duration || formData.duration <= 0 || formData.duration > 12)
    //   newErrors.duration =
    //     "Duration must be greater than zero months or less than 12 month.";
    return newErrors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    setIsSubmitting(true);

    const formattedMessage = `
    New Form Submission:

     Name: ${formData.name}
    Email: ${formData.email}
    Phone: ${formData.phone}
    Amount: ${formData.amount}
    Duration: 12 Months
   
  `;

    try {
      await emailjs.send(
        "service_irvddyu",
        "template_5mkkf4u",
        { message: formattedMessage },
        "Z9DDSWFcmDA5DuJx-"
      );
      toast.success("Form submitted successfully!");
      setFormData({
        name: "",
        email: "",
        phone: "",
        amount: "",
        duration: "",
      });
      onClose();
    } catch (error) {
      toast.error("Error submitting the form. Please try again.");
      console.error("EmailJS Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        padding: "15%",
        overflow: "auto",
      }}
    >
      <ToastContainer />
      <div
        style={{
          background: "#fff",
          borderRadius: "8px",
          width: "100%",
          maxWidth: "100%",
          padding: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          marginTop: "10%",
        }}
      >
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr", // Two columns of equal width
              gap: "15px", // Space between grid items
              marginBottom: "20px",
            }}
          >
            <div>
              <label style={{ display: "block", marginBottom: "5px" }}>
                Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                disabled={isSubmitting}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
              {errors.name && (
                <small style={{ color: "red" }}>{errors.name}</small>
              )}
            </div>

            <div>
              <label style={{ display: "block", marginBottom: "5px" }}>
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                disabled={isSubmitting}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
              {errors.email && (
                <small style={{ color: "red" }}>{errors.email}</small>
              )}
            </div>

            <div>
              <label style={{ display: "block", marginBottom: "5px" }}>
                Phone
              </label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                disabled={isSubmitting}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
              {errors.phone && (
                <small style={{ color: "red" }}>{errors.phone}</small>
              )}
            </div>

            <div>
              <label style={{ display: "block", marginBottom: "5px" }}>
                Amount
              </label>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                disabled={isSubmitting}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
              {errors.amount && (
                <small style={{ color: "red" }}>{errors.amount}</small>
              )}
            </div>

            <div style={{ gridColumn: "1 / span 2" }}>
              <label style={{ display: "block", marginBottom: "5px" }}>
                Duration (months)
              </label>
              <input
                type="text"
                name="duration"
                value={"12 Months"}
                readOnly
                onChange={handleInputChange}
                disabled={isSubmitting}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
              {errors.duration && (
                <small style={{ color: "red" }}>{errors.duration}</small>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <button
              type="button"
              onClick={onClose}
              disabled={isSubmitting}
              style={{
                backgroundColor: "#ccc",
                color: "#000",
                padding: "10px 20px",
                border: "none",
                borderRadius: "4px",
                cursor: isSubmitting ? "not-allowed" : "pointer",
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              style={{
                backgroundColor: isSubmitting ? "#aaa" : "#F90303",
                color: "#fff",
                padding: "10px 20px",
                border: "none",
                borderRadius: "4px",
                cursor: isSubmitting ? "not-allowed" : "pointer",
              }}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
