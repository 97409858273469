import Header from "./components/Header";
import Banner from "./components/Banner";
import { useState } from "react";
import Hero from "./components/Hero";
import Modal from "./components/Modal";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <div className="App">
      {/* <ToastContainer /> */}
      <Header />
      {/* <Banner /> */}
      <Hero />
      <div
        style={{ height: "46px" }}
        aria-hidden="true"
        className="wp-block-spacer"
      ></div>
      <div className=" container-fluid mb-2">
        <div className="wp-bootstrap-blocks-row row g-3">
          <div className="col-12 col-lg-4 text-center">
            <h2 className="wp-block-heading has-primary-color has-text-color has-link-color wp-elements-abb22cf745916b0839cf93d02228b760">
              <strong>₦22 million +</strong>
            </h2>
            <p className="has-primary-color has-text-color has-link-color has-medium-font-size wp-elements-be1db0f88afc21c68b872b0a8a93deb4">
              Interest earned by investors
            </p>
          </div>
          <div className="col-12 col-lg-4 text-center">
            <h2 className="wp-block-heading has-primary-color has-text-color has-link-color wp-elements-d3753201fe0949598a2894f887c42cfc">
              <strong>16000</strong>
            </h2>
            <p className="has-primary-color has-text-color has-link-color has-medium-font-size wp-elements-242e6742c107804583262653df85ab95">
              Registered users
            </p>
          </div>
          <div className="col-12 col-lg-4 text-center">
            <h2 className="wp-block-heading has-primary-color has-text-color has-link-color wp-elements-e8c4b82adaaf491fb35637bf830317c8">
              <strong>0%</strong>
            </h2>
            <p className="has-primary-color has-text-color has-link-color has-medium-font-size wp-elements-459ab8db5e43a3f7557ba7bde913a946">
              Fees to investors
            </p>
          </div>
        </div>
      </div>
      <div
        style={{ height: "46px" }}
        aria-hidden="true"
        class="wp-block-spacer"
      ></div>
      <div className="block block--average-return wp-block-average-return">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-5 offset-xl-1 col-xl-4">
              <div className="block--average-return-circle">
                <div className="content">
                  <div className="stats">30%</div>
                  <div className="label">Annual Return</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 pt-4 py-lg-5">
              <h2 className="">Put your money to work</h2>
              <p data-pm-slice="1 1 []">
                Annual investment returns delivered over the last 12 months.
                Higher than traditional asset classes. You continue to earn
                interest while the invested loan is overdue.
              </p>
              <div className="wp-block-buttons is-content-justification-left is-layout-flex wp-container-core-buttons-is-layout-1 wp-block-buttons-is-layout-flex">
                <div className="wp-block-button">
                  <button
                    className="wp-block-button__link has-purple-background-color has-background wp-element-button"
                    onClick={openModal}
                  >
                    <strong>Invest now</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default App;
