export default function Header() {
  return (
    <div
      class="fixed-top main-header"
      id="main-header"
      data-controller="header"
    >
      {/* <div
        class="container-fluid bg-primary text-white cookie-bar"
        id="cookie-bar"
        data-header-target="cookieBar"
      >
        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-between px-3">
            <div class="d-lg-flex cookie-bar">
              <div class="mb-0 fw-light">
                <p class="mb-0">
                  We use cookies to enhance your website experience. By using
                  this website, you agree to our use of cookies.
                </p>
              </div>
              <a
                class="text-secondary text-decoration-none ms-lg-2"
                href="cookie-policy/index.html"
              >
                Learn More
              </a>
            </div>
            <button
              type="button"
              class="btn text-white btn--cookie"
              aria-label="Close cookie notification"
              data-action="click->header#closeCookie"
            >
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
        </div>
      </div> */}
      <nav class="navbar navbar-expand-lg bg-white navbar-light">
        <div class="container-fluid">
          <a href="#" class="align-items-center d-none d-lg-block">
            <img
              loading="lazy"
              class="h-8 w-auto"
              src="/uploads/logo.png"
              width="238"
              height="36"
              alt="Lendermarket"
              title="Lendermarket"
            />
          </a>
          <img
            loading="lazy"
            class="h-8 w-auto"
            src="/uploads/logo.png"
            width="238"
            height="36"
            alt="Lendermarket"
            title="Lendermarket"
          />
          {/* <button
            class="navbar-toggler px-1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button> */}
          <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
            <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
              <a href="#" class="align-items-center d-none d-lg-block">
                <img
                  loading="lazy"
                  class="h-8 w-auto"
                  src="/uploads/logo.png"
                  width="238"
                  height="36"
                  alt="Lendermarket"
                  title="Lendermarket"
                />
              </a>
              {/* <div class="menu-primary-container">
                <ul
                  id="menu-primary"
                  class="nav justify-content-center flex-column flex-lg-row text-center"
                >
                  <li
                    id="menu-item-209"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a
                      class="nav-link mx-1 px-2 link-secondary"
                      href="about/index.html"
                    >
                      About Us
                    </a>
                  </li>
                  <li
                    id="menu-item-208"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-208"
                  >
                    <a
                      class="nav-link mx-1 px-2 link-secondary"
                      href="https://app2.lendermarket.com/loans"
                    >
                      Loan Listings
                    </a>
                  </li>
                  <li
                    id="menu-item-3388"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3388"
                  >
                    <a
                      class="nav-link mx-1 px-2 link-secondary"
                      href="loan-originators/index.html"
                    >
                      Loan Originators
                    </a>
                  </li>
                  <li
                    id="menu-item-210"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-210"
                  >
                    <a
                      class="nav-link mx-1 px-2 link-secondary"
                      href="howitworks/index.html"
                    >
                      How It Works
                    </a>
                  </li>
                  <li
                    id="menu-item-211"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-211"
                  >
                    <a
                      class="nav-link mx-1 px-2 link-secondary"
                      href="faq/index.html"
                    >
                      FAQ
                    </a>
                  </li>
                  <li
                    id="menu-item-212"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-212"
                  >
                    <a
                      class="nav-link mx-1 px-2 link-secondary"
                      href="blog/index.html"
                    >
                      Blog
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
