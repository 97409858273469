import React, { useState } from "react";
import Modal from "./Modal";

export default function Hero() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div
      class="wp-block-media-text has-media-on-the-right is-stacked-on-mobile has-background"
      style={{
        backgroundColor: ":#ffffff",
        gridTemplateColumns: "auto 52%",
        marginTop: "5%",
      }}
    >
      <div class="wp-block-media-text__content">
        <div
          style={{ height: "22px" }}
          aria-hidden="true"
          class="wp-block-spacer"
        ></div>
        <h2 class="wp-block-heading has-primary-color has-text-color has-link-color has-large-font-size wp-elements-42979aafbe01650b8c11054f8d299b1c">
          <strong>
            Give Loan to Salary Earners who need to pay House Rent
          </strong>
        </h2>
        <div
          style={{ height: "20px" }}
          aria-hidden="true"
          class="wp-block-spacer"
        ></div>
        <div
          style={{ height: "22px" }}
          aria-hidden="true"
          class="wp-block-spacer"
        ></div>
        <p
          class="has-primary-color has-text-color has-link-color has-medium-font-size wp-elements-ce069c97c6c9fc64b68323f9bc985613"
          style={{ display: "flex", gap: "2%" }}
        >
          <img
            decoding="async"
            width="20"
            height="20"
            class="wp-image-15326"
            style={{ width: "20px" }}
            src="/uploads/2024/05/icons8-check.svg"
            alt="invest"
          />
          <strong>Invest</strong> in Loans
        </p>
        <p
          class="has-primary-color has-text-color has-link-color has-medium-font-size wp-elements-0221d1a251d2f7302f3b09073d65a614"
          style={{ display: "flex", gap: "2%" }}
        >
          <img
            decoding="async"
            width="20"
            height="20"
            className="wp-image-15326"
            style={{ width: "20px" }}
            src="/uploads/2024/05/icons8-check.svg"
            alt="earn"
          />
          <strong>Earn</strong> 2.5% interest monthly
        </p>

        <p
          class="has-primary-color has-text-color has-link-color has-medium-font-size wp-elements-ce069c97c6c9fc64b68323f9bc985613"
          style={{ display: "flex", gap: "2%" }}
        >
          <img
            decoding="async"
            width="20"
            height="20"
            className="wp-image-15326"
            style={{ width: "20px" }}
            src="/uploads/2024/05/icons8-check.svg"
            alt="relax"
          />
          <strong>Deduction </strong> from source.
        </p>
        <p
          class="has-primary-color has-text-color has-link-color has-medium-font-size wp-elements-ce069c97c6c9fc64b68323f9bc985613"
          style={{ display: "flex", gap: "2%" }}
        >
          <img
            decoding="async"
            width="20"
            height="20"
            className="wp-image-15326"
            style={{ width: "20px" }}
            src="/uploads/2024/05/icons8-check.svg"
            alt="relax"
          />
          <strong>Get </strong> started today!
        </p>
        <div
          style={{ height: "31px" }}
          aria-hidden="true"
          class="wp-block-spacer"
        ></div>
        <div className="wp-block-buttons is-content-justification-left is-layout-flex wp-container-core-buttons-is-layout-1 wp-block-buttons-is-layout-flex">
          <div className="wp-block-button">
            <button
              className="wp-block-button__link has-purple-background-color has-background wp-element-button"
              onClick={openModal}
            >
              <strong>Invest now</strong>
            </button>
          </div>
        </div>
        <div
          style={{ height: "22px" }}
          aria-hidden="true"
          class="wp-block-spacer"
        ></div>
        <div
          style={{ height: "20px" }}
          aria-hidden="true"
          className="wp-block-spacer"
        ></div>
        <div
          style={{ height: "22px" }}
          aria-hidden="true"
          className="wp-block-spacer"
        ></div>
      </div>
      <figure className="wp-block-media-text__media">
        <img
          fetchpriority="high"
          decoding="async"
          width="848"
          height="728"
          src="https://paymyrent.ng/static/media/hero.b9783745.png"
          alt="hero"
          className="wp-image-15872 size-full"
          srcset="https://paymyrent.ng/static/media/hero.b9783745.png"
          sizes="(max-width: 848px) 100vw, 848px"
        />
      </figure>
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}
